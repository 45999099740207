/* eslint-disable import/no-named-as-default */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Camera from '../camera/camera';
import Preview from '../preview/preview';
var Scan = function (_a) {
    var imgURL = _a.imgURL, errorMessage = _a.errorMessage;
    var _b = useState(false), isCameraOpen = _b[0], setIsCameraOpen = _b[1];
    useEffect(function () {
        setIsCameraOpen(false);
    }, [imgURL, errorMessage]);
    var openCameraHandler = function () {
        setIsCameraOpen(true);
    };
    return (React.createElement("section", { className: "scan" },
        isCameraOpen && React.createElement(Camera, null),
        !imgURL
            && React.createElement("button", { type: "button", className: "scan__shot-btn", onClick: openCameraHandler }, "\u0421\u043A\u0430\u043D\u0438\u0440\u043E\u0432\u0430\u0442\u044C"),
        !isCameraOpen && imgURL
            && React.createElement(Preview, { imgSrc: imgURL, cameraBtnClickHandler: setIsCameraOpen })));
};
Scan.propTypes = {
    imgURL: PropTypes.string.isRequired,
    errorMessage: PropTypes.string.isRequired,
};
var mapStateToProps = function (state) { return ({
    imgURL: state.imgURL,
    errorMessage: state.error,
}); };
export { Scan };
export default connect(mapStateToProps, null)(Scan);
