import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import App from './components/app/app';
import reducer from './store/reducer';
var store = createStore(reducer, composeWithDevTools());
ReactDOM.render(React.createElement(Provider, { store: store },
    React.createElement(App, null)), document.querySelector('#root'));
