import React from 'react';
var OutputDesk = function () {
    return (React.createElement("section", { className: "output-desk" },
        React.createElement("table", { className: "class-desk__table" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "\u041D\u0430\u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u0438\u0435 "),
                    React.createElement("th", null, " \u0412\u0435\u0440\u043E\u044F\u0442\u043D\u043E\u0441\u0442\u044C % "),
                    React.createElement("th", null, " \u041F\u0440\u0438\u043D\u0438\u043C\u0430\u0435\u0442\u0441\u044F "))),
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", null, "\u043E\u043F\u0438\u0441\u0430\u043D\u0438\u0435 \u043A\u043B\u0430\u0441\u0441\u0430"))))));
};
export default OutputDesk;
