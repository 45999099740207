import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import PageHeader from '../page-header/page-header';
import PageMain from '../page-main/page-main';
import PageFooter from '../page-footer/page-footer';
import Model from '../../assets/neuronet/Model';
var model = new Model();
model.loadModel()
    .then(function () {
    // Фейковый вызов предсказания для ускорения последующих вызовов
    model.makeFakePredictions();
})
    .catch(function (exception) {
    // eslint-disable-next-line no-console
    console.log('Не удалось загрузить модель данных: \n ', exception);
});
var App = function () { return (React.createElement(BrowserRouter, null,
    React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: "/" },
            React.createElement("div", { className: "app" },
                React.createElement(PageHeader, null),
                React.createElement(PageMain, { model: model }),
                React.createElement(PageFooter, null)))))); };
App.propTypes = {};
export default App;
