/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setImage } from '../../store/action.ts';
import useUserMedia from '../../hooks/use-user-media';
var CAPTURE_OPTIONS = {
    audio: false,
    video: { facingMode: 'environment' },
};
var getDrawingRectParams = function (streamRect) {
    var width = streamRect.width, height = streamRect.height;
    var sx;
    var sy;
    var shortSide;
    if (height < width) {
        sx = Math.round((width - height) / 2);
        sy = 0;
        shortSide = height;
    }
    else if (height > width) {
        sx = 0;
        sy = Math.round((height - width) / 2);
        shortSide = width;
    }
    else {
        sx = 0;
        sy = 0;
        shortSide = width;
    }
    return {
        sx: sx,
        sy: sy,
        sWidth: shortSide,
        sHeight: shortSide,
        dx: 0,
        dy: 0,
        dWidth: shortSide,
        dHeight: shortSide,
    };
};
var getFilledCanvas = function (mediaStream, videoRef) {
    var _a = mediaStream.getVideoTracks()[0].getSettings(), width = _a.width, height = _a.height;
    var _b = getDrawingRectParams({ width: width, height: height }), sx = _b.sx, sy = _b.sy, sWidth = _b.sWidth, sHeight = _b.sHeight, dx = _b.dx, dy = _b.dy, dWidth = _b.dWidth, dHeight = _b.dHeight;
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    context.canvas.width = sWidth;
    context.canvas.height = sWidth;
    context.drawImage(videoRef.current, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
    return canvas;
};
var Camera = function (_a) {
    var setSnapshotToStore = _a.setSnapshotToStore;
    var _b = useState({ current: null }), videoRef = _b[0], setVideoRef = _b[1];
    var callback = useCallback(function (node) {
        setVideoRef({ current: node });
    }, []);
    var _c = useState(false), isVideoPlaying = _c[0], setIsVideoPlaying = _c[1];
    var mediaStream = useUserMedia(CAPTURE_OPTIONS);
    if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
        videoRef.current.srcObject = mediaStream;
    }
    var handleCanPlay = function () {
        setIsVideoPlaying(true);
        videoRef.current.play();
    };
    var captureHandler = function () {
        var filledCanvas = getFilledCanvas(mediaStream, videoRef);
        var base64ImgURL = filledCanvas.toDataURL();
        setSnapshotToStore(base64ImgURL);
    };
    if (!mediaStream) {
        return null;
    }
    return (React.createElement("div", { className: "camera" },
        React.createElement("video", { className: "camera__video", ref: callback, onCanPlay: handleCanPlay, autoPlay: true, playsInline: true, muted: true, width: "100px", heigth: "100px" }),
        React.createElement("div", { className: "camera__overlay", hidden: !isVideoPlaying }),
        React.createElement("button", { type: "button", className: "camera__shot-btn", onClick: captureHandler })));
};
Camera.propTypes = {
    setSnapshotToStore: PropTypes.func.isRequired,
};
var mapDispatchToProps = function (dispatch) { return ({
    setSnapshotToStore: function (imgURL) {
        return dispatch(setImage(imgURL));
    },
}); };
export { Camera };
export default connect(null, mapDispatchToProps)(Camera);
