/* eslint-disable import/prefer-default-export */
export var tetraPak = {
    tetraPak: {
        category: 'tetra-pak',
        isAcceptable: true,
        title: 'Тетрапак и аналоги',
        desc: "C\u043E\u0431\u0438\u0440\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439 \u0442\u0435\u0440\u043C\u0438\u043D \u0434\u043B\u044F \u043C\u043D\u043E\u0433\u043E\u0441\u043B\u043E\u0439\u043D\u043E\u0439 \u0443\u043F\u0430\u043A\u043E\u0432\u043A\u0438 \u043F\u0440\u043E\u0434\u0443\u043A\u0442\u043E\u0432.\n          \u0423\u043F\u0430\u043A\u043E\u0432\u043A\u0430 \u0441\u043E\u0441\u0442\u043E\u0438\u0442 \u0438\u0437 \u043D\u0435\u0441\u043A\u043E\u043B\u044C\u043A\u0438\u0445 \u0441\u043B\u043E\u0451\u0432 \u043A\u0430\u0440\u0442\u043E\u043D\u0430, \u043F\u043E\u043B\u0438\u044D\u0442\u0438\u043B\u0435\u043D\u0430 \u0438 \u0444\u043E\u043B\u044C\u0433\u0438.\n          \u041D\u0435 \u043E\u0442\u043D\u043E\u0441\u0438\u0442\u0441\u044F \u043A \u043C\u0430\u043A\u0443\u043B\u0430\u0442\u0443\u0440\u0435!\n          \u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u043F\u043E\u0448\u043B\u043E \u043E\u0442 \u043D\u0430\u0437\u0432\u0430\u043D\u0438\u044F \u0442\u043E\u0440\u0433\u043E\u0432\u043E\u0439 \u043C\u0430\u0440\u043A\u0438 Tetra Pak, \u0442\u0430\u043A\u0436\u0435 \u043A \u044D\u0442\u043E\u0439  \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u0438 \u0443\u043F\u0430\u043A\u043E\u0432\u043A\u0435 \u043E\u0442\u043D\u043E\u0441\u044F\u0442\u0441\u044F \u0434\u0440\u0443\u0433\u0438\u0435 \u043C\u0430\u0440\u043A\u0438: Pure Pac, Tralin Pak, \u041A\u043E\u043C\u0431\u0438\u0431\u043B\u043E \u0438 \u0434\u0440\u0443\u0433\u0438\u0435.\n          \u0418\u043D\u043E\u0433\u0434\u0430 \u043D\u0430 \u0442\u0430\u043A\u0438\u0445 \u0443\u043F\u0430\u043A\u043E\u0432\u043A\u0430\u0445 \u0441\u0442\u043E\u0438\u0442 \u043C\u0430\u0440\u043A\u0438\u0440\u043E\u0432\u043A\u0430 81, 82 \u0438\u043B\u0438 84(\u043F\u043E\u0434\u0440\u043E\u0431\u043D\u0435\u0435 \u043F\u0440\u043E \u043C\u0430\u0440\u043A\u0438\u0440\u043E\u0432\u043A\u0438 \u0443\u043F\u0430\u043A\u043E\u0432\u043A\u0438).",
        examples: 'многослойная твёрдая упаковка марок TetraPak, PurePak, EloPak, SIG',
        preparingRules: [''],
    },
};
