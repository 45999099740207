import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resetAll } from '../../store/action.ts';
var Preview = function (_a) {
    var imgSrc = _a.imgSrc, cameraBtnClickHandler = _a.cameraBtnClickHandler, resetAllInStore = _a.resetAllInStore;
    var resetHandler = function () {
        resetAllInStore();
    };
    return (React.createElement("div", { className: "preview" },
        React.createElement("img", { className: "preview__render", src: imgSrc, alt: "Preview \u043F\u0440\u0435\u0434\u043F\u0440\u043E\u0441\u043C\u043E\u0442\u0440", width: "280", height: "280" }),
        !imgSrc && React.createElement("p", null, "Nothing to displa"),
        React.createElement("div", { className: "preview__controls" },
            React.createElement("button", { type: "button", className: "preview__btn preview__btn--camera", onClick: cameraBtnClickHandler }, "Take new photo"),
            React.createElement("button", { type: "button", className: "preview__btn preview__btn--reset", onClick: resetHandler }, "Reset photo"))));
};
Preview.propTypes = {
    imgSrc: PropTypes.string,
    resetAllInStore: PropTypes.func.isRequired,
    cameraBtnClickHandler: PropTypes.func.isRequired,
};
Preview.defaultProps = {
    imgSrc: '',
};
var mapDispatchToProps = function (dispatch) { return ({
    resetAllInStore: function () {
        dispatch(resetAll());
    },
}); };
export { Preview };
export default connect(null, mapDispatchToProps)(Preview);
