export var paper = {
    corrugatedBoard: {
        category: 'paper',
        isAcceptable: true,
        title: 'Гофрокартон',
        desc: 'Гофрокартон (гофрированный картон) — это упаковочный материал, состоящий из склеенных бумажных слоев, как минимум один из которых представляет собой гофрированный (сложенный гармошкой) лист.',
        examples: '',
        preparingRules: ['paper'],
    },
    craftPaper: {
        category: 'paper',
        isAcceptable: true,
        title: 'Крафт-бумага',
        desc: '(нем. Kraft — сила) — высокопрочная обёрточная бумага из слабопроваренной длинноволокнистой сульфатной целлюлозы. Производится из древесины в процессе сульфатной варки, также известной как крафт-процесс. Используется для упаковочных целей, а также изготовления бумажных изделий, обязанных быть прочными и износостойкими — гофрокартона, крафт-мешков, пакетов, конвертов, бумажных шпагатов и т. п. Обычно крафт-бумага производится коричневого цвета, однако может быть и отбеленной.',
        examples: 'пакеты с бумажной ручкой, упаковочная крафт- бумага, бумага тишью светлых цветов',
        preparingRules: ['paper'],
    },
    hardPaperCup: {
        category: '',
        isAcceptable: false,
        title: 'Бумажный стаканчик',
        desc: 'Бумажные стаканчики не являются чисто бумажными. Сама по себе целлюлоза будет пропускать жидкость. Стаканчики бывают трёх видов: - ламинированные – целлюлоза, покрытая слоем тонкой пленки; - вощеные – целлюлоза, пропитанная воском; - биоразлагаемые – сделаны из растительного крахмала и полилактида. Следовательно, они по-разному перерабатываются, их нужно собирать раздельно. Из-за того, что их сложно отличить друг от друга, требуется отдельная система сбора и ручная досортировка, которая затрудняется загрязненностью (остатки жидкости). Таким образом, сбор такого вторсырья становится нерентабельным для заготовителя и/или переработчика. Поэтому их никто не принимает.',
        examples: '',
        preparingRules: ['paper'],
    },
    packOfCigarettes: {
        category: '',
        isAcceptable: false,
        title: 'Пачка сигарет',
        desc: 'Пачка сигарет - описание пачки сигарет',
        examples: 'Любые пачки сигарет',
        preparingRules: ['paper'],
    },
    pulpPaper: {
        category: 'paper',
        isAcceptable: true,
        title: 'Пульперкартон',
        desc: 'Пульперкартон — это вид макулатуры, прошедшей много циклов переработки и уже не подходящей для более качественной бумаги и картона. С каждым этапом переработки макулатуры целлюлозное волокно становится короче и уже хуже соединяется между собой, поэтому короткое волокно используется на последних стадиях переработки макулатуры  для производства туалетной бумаги, салфеток, бумажных полотенец и пульеркартона.',
        preparingRules: ['paper'],
        examples: 'упаковки из-под яиц и аналогичные упаковки.',
    },
    simplePaper: {
        category: 'paper',
        isAcceptable: true,
        title: 'Бумага и тонкий картон',
        desc: 'описание бумаги',
        examples: 'бумага (можно с рисунком), бумага после шредера, журналы, газеты, книги, тетради, альбомы, листовки, брошюры, НЕ гофрированный картон, любые бумажные втулки',
        preparingRules: ['paper'],
    },
};
