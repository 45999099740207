import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDescriptionByClassName } from '../../assets/utils.ts';
import { setActiveClass, setPredictions } from '../../store/action.ts';
import Model from '../../assets/neuronet/Model';
var getFormatedPredictions = function (predicts) {
    var MAX_PREDICTIONS_NUMBER = 5;
    var mostValuePredicts = predicts.slice(0, MAX_PREDICTIONS_NUMBER);
    var convertedPredicts = mostValuePredicts.map(function (predict) {
        var probability = predict.probability * 100;
        probability = probability < 1 ? 'менее 1%' : probability.toFixed(2);
        var classDescription = getDescriptionByClassName(predict.className);
        var isAcceptable = classDescription.isAcceptable ? '✔️' : '❌';
        return ({
            id: predict.className,
            title: classDescription.title,
            probability: probability,
            isAcceptable: isAcceptable,
        });
    });
    return convertedPredicts;
};
var OutputDesk = function (_a) {
    var model = _a.model, imgURL = _a.imgURL, setPredictionsToStore = _a.setPredictionsToStore, setActiveClassToStore = _a.setActiveClassToStore, activeClass = _a.activeClass;
    var _b = useState([]), predictions = _b[0], setPredictionsToState = _b[1];
    useEffect(function () {
        if (!imgURL) {
            setPredictionsToState([]);
        }
        model.getPredictions(imgURL)
            .then(function (predicts) {
            setPredictionsToStore(predicts);
            var formattedPredictions = getFormatedPredictions(predicts);
            setPredictionsToState(formattedPredictions);
            setActiveClassToStore(predicts[0].className);
        });
    }, [model, imgURL, setPredictionsToStore, setActiveClassToStore]);
    var rowClickHandler = function (evt) {
        var selectedRow = evt.target.closest('tr');
        var rowId = selectedRow.getAttribute('id');
        setActiveClassToStore(rowId);
    };
    var getRowClassName = function (currentPredictId) {
        var shouldBeInitiate = predictions && !activeClass;
        var activeId = shouldBeInitiate ? predictions[0].id : activeClass;
        var className = activeId === currentPredictId ? 'output-desk__active-row' : '';
        return className;
    };
    return (React.createElement("section", { className: "output-desk" },
        React.createElement("h2", { className: "output-desk__title" }, "\u0414\u0430\u043D\u043D\u044B\u0435 \u0440\u0430\u0441\u043F\u043E\u0437\u043D\u0430\u0432\u0430\u043D\u0438\u044F"),
        React.createElement("table", { className: "output-desk__table" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "\u041D\u0430\u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u0438\u0435"),
                    React.createElement("th", null, "\u0412\u0435\u0440\u043E\u044F\u0442\u043D\u043E\u0441\u0442\u044C %"),
                    React.createElement("th", null, "\u041F\u0440\u0438\u043D\u0438\u043C\u0430\u0435\u0442\u0441\u044F"))),
            React.createElement("tbody", null, predictions.map(function (predict) { return (React.createElement("tr", { key: predict.id, id: predict.id, className: getRowClassName(predict.id), onClick: rowClickHandler },
                React.createElement("td", { className: "output-desk__predict-title" }, "" + predict.title),
                React.createElement("td", null, "" + predict.probability),
                React.createElement("td", null, "" + predict.isAcceptable))); })))));
};
OutputDesk.propTypes = {
    model: PropTypes.instanceOf(Model).isRequired,
    imgURL: PropTypes.string.isRequired,
    setPredictionsToStore: PropTypes.func.isRequired,
    setActiveClassToStore: PropTypes.func.isRequired,
    activeClass: PropTypes.string.isRequired,
};
var mapStateToProps = function (state) { return ({
    imgURL: state.imgURL,
    activeClass: state.activeClass,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setPredictionsToStore: function (predictions) {
        dispatch(setPredictions(predictions));
    },
    setActiveClassToStore: function (activeClass) {
        dispatch(setActiveClass(activeClass));
    },
}); };
export { OutputDesk };
export default connect(mapStateToProps, mapDispatchToProps)(OutputDesk);
