import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resetError } from '../../store/action';
var ErrorScreen = function (_a) {
    var errorMessage = _a.errorMessage, resetErrorAction = _a.resetErrorAction;
    if (!errorMessage) {
        return null;
    }
    return (React.createElement("section", { className: "error-screen" },
        React.createElement("h1", { className: "error-screen__title" }, "\u041E\u0448\u0438\u0431\u043A\u0430"),
        React.createElement("p", { className: "error-screen__reason" }, errorMessage),
        React.createElement("button", { type: "button", className: "button", onClick: resetErrorAction }, "\u0417\u0410\u041A\u0420\u042B\u0422\u042C")));
};
ErrorScreen.propTypes = {
    errorMessage: PropTypes.string.isRequired,
    resetErrorAction: PropTypes.func.isRequired,
};
var mapStateToProps = function (state) { return ({
    errorMessage: state.error,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    resetErrorAction: function () {
        dispatch(resetError());
    },
}); };
export { ErrorScreen };
export default connect(mapStateToProps, mapDispatchToProps)(ErrorScreen);
