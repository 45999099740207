import { kebabToCamelCase } from '../utils';
import idClasses from './id-classes/id-classes';
var emptyDescription = {
    category: 'unknonCategory',
    isAcceptable: false,
    title: 'emptyTitle',
    desc: 'emptyDesctiption',
    examples: 'no examples',
    preparingRules: [],
};
export var getDescriptionByClassName = function (className) {
    var classNameParts = className.split('__');
    var SHORT_NAME_INDEX = 1;
    var shortName = classNameParts[SHORT_NAME_INDEX];
    var cameledName = kebabToCamelCase(shortName);
    var description = idClasses[cameledName] || emptyDescription;
    return description;
};
