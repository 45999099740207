export var ActionType = {
    SET_IMAGE_URL: 'SET_IMAGE_URL',
    RESET_IMAGE: 'RESET_IMAGE',
    SET_PREDICTIONS: 'SET_PREDICTIONS',
    RESET_PREDICTIONS: 'RESET_PREDICTIONS',
    SET_ERROR: 'SET_ERROR',
    RESET_ERROR: 'RESET_ERROR',
    SET_ACTIVE_CLASS: 'SET_ACTIVE_CLASS',
    RESET_ACTIVE_CLASS: 'RESET_ACTIVE_CLASS',
    RESET_ALL: 'RESET_ALL',
};
export var setImage = function (imageURL) { return ({
    type: ActionType.SET_IMAGE_URL,
    payload: imageURL,
}); };
export var resetImage = function () { return ({
    type: ActionType.RESET_IMAGE,
}); };
export var setPredictions = function (predictions) { return ({
    type: ActionType.SET_PREDICTIONS,
    payload: predictions,
}); };
export var resetPredictions = function () { return ({
    type: ActionType.RESET_PREDICTIONS,
}); };
export var setError = function (error) { return ({
    type: ActionType.SET_ERROR,
    payload: error,
}); };
export var resetError = function () { return ({
    type: ActionType.RESET_ERROR,
}); };
export var setActiveClass = function (activePredict) { return ({
    type: ActionType.SET_ACTIVE_CLASS,
    payload: activePredict,
}); };
export var resetActiveClass = function () { return ({
    type: ActionType.RESET_ACTIVE_CLASS,
}); };
export var resetAll = function () { return ({
    type: ActionType.RESET_ALL,
}); };
