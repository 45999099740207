var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionType } from './action';
var initialState = {
    imgURL: '',
    predictions: [],
    error: '',
    activeClass: '',
};
var extend = function (a, b) { return (__assign(__assign({}, a), b)); };
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionType.SET_IMAGE_URL:
            return extend(state, {
                imgURL: action.payload,
            });
        case ActionType.RESET_IMAGE:
            return extend(state, {
                imgURL: '',
            });
        case ActionType.SET_PREDICTIONS:
            return extend(state, {
                predictions: action.payload,
            });
        case ActionType.RESET_PREDICTIONS:
            return extend(state, {
                predictions: [],
            });
        case ActionType.SET_ERROR:
            return extend(state, {
                error: action.payload,
            });
        case ActionType.RESET_ERROR:
            return extend(state, {
                error: '',
            });
        case ActionType.SET_ACTIVE_CLASS:
            return extend(state, {
                activeClass: action.payload,
            });
        case ActionType.RESET_ACTIVE_CLASS:
            return extend(state, {
                activeClass: '',
            });
        case ActionType.RESET_ALL:
            return extend(state, {
                activeClass: '',
                error: '',
                predictions: [],
                imgURL: '',
            });
        default:
            return state;
    }
};
export default reducer;
