/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import OutputDesk from '../output-desk/output-desk';
import ClassDesk from '../class-desk/class-desk.tsx';
import ErrorScreen from '../error-screen/error-screen.tsx';
import Scan from '../scan/scan';
import Model from '../../assets/neuronet/Model';
var PageMain = function (_a) {
    var model = _a.model;
    return (React.createElement(React.Fragment, null,
        React.createElement("main", { className: "page-main container" },
            React.createElement("div", { className: "page-main__flex" },
                React.createElement(Scan, null),
                React.createElement("div", { className: "page-main__output" },
                    React.createElement(OutputDesk, { model: model }),
                    React.createElement(ClassDesk, null)))),
        React.createElement(ErrorScreen, null)));
};
PageMain.propTypes = {
    model: PropTypes.instanceOf(Model).isRequired,
};
export default PageMain;
