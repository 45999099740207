var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable comma-dangle */
// eslint-disable-next-line import/prefer-default-export
export var extend = function (a, b) { return (__assign(__assign({}, a), b)); };
var capitalize = function (str) {
    if (typeof str !== 'string') {
        return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
};
export var kebabToCamelCase = function (str) {
    var strParts = str.split('-');
    strParts = [].concat(strParts[0], strParts.slice(1).map(function (part) { return capitalize(part); }));
    return strParts.join('');
};
